<template>
  <header class="d-flex bg-black pl-3 pl-lg-0 py-lg-4">
    <v-container class="d-flex justify-lg-space-between align-center pa-0 pl-md-5">

      <!-- Лого и титл -->
      <div class="d-flex align-center">
        <v-toolbar-title class="mr-lg-8 ml-md-5">
          <img :src="require('../assets/logo.png')" alt="Logo" height="40">
        </v-toolbar-title>
        <v-spacer class="hidden-md-and-up"/>
        <div class="d-block">
          <p class="text-orange text-h6 hidden-md-and-down" style="line-height: 1">Klimat Avto</p>
          <p class="hidden-md-and-down">Ваше авто - Ваш климат</p>
        </div>
      </div>
      <v-spacer class="hidden-md-and-up"/>
      <p class="text-orange text-h6 hidden-md-and-up">Klimat Avto</p>
      <v-spacer class="hidden-md-and-up"/>

      <!-- Меню для десктопа -->
      <div class="d-flex align-center gap-5">
        <v-btn variant="text" class="hidden-md-and-down text-h6 text-capitalize">Услуги</v-btn>
        <v-btn variant="text" class="hidden-md-and-down text-h6 text-capitalize">Контакты</v-btn>
        <v-btn color="black" icon="mdi-whatsapp" class="hidden-md-and-down"></v-btn>
        <v-btn color="black" icon="mdi-instagram" class="hidden-md-and-down"></v-btn>
        <v-btn color="black" icon="mdi-phone-outline" class="hidden-md-and-down"></v-btn>
      </div>

      <!-- Меню для мобилок -->
      <v-speed-dial
          location="bottom center"
          transition="fade-transition"
      >
        <template v-slot:activator="{ props: activatorProps }">
          <v-app-bar-nav-icon
              class="hidden-md-and-up"
              size="x-large" color="#41a7d2"
              v-bind="activatorProps"
              @click="toggleDrawer"
          />
        </template>

        <!-- Добавляем ключи к дочерним элементам -->
        <template v-for="(item, index) in menuItems" :key="index">
          <a :href="item.link" target="_blank" rel="noopener noreferrer">
            <v-btn :color="item.color" :icon="item.icon" :variant="item.variant"/>
          </a>
        </template>

      </v-speed-dial>

    </v-container>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      menuItems: [
        {
          link: 'https://www.instagram.com/klimat_komtrol/',
          color: 'pink-darken-1',
          icon: 'mdi-instagram',
          variant: 'flat'
        },
        {link: 'https://www.youtube.com/@user-xo5pl8he9g/videos', color: 'red', icon: 'mdi-youtube', variant: 'flat'},
        {
          link: 'https://wa.me/77079208324?text=Здравствуйте, пишу с сайта%0A',
          color: 'green',
          icon: 'mdi-whatsapp',
          variant: 'flat'
        },
        {
          link: 'https://t.me/+77079208324?text=Здравствуйте, пишу с сайта%0A',
          color: 'blue',
          icon: 'mdi-telegram',
          variant: 'flat'
        },
        {link: 'tel:+77079208324', color: 'white', icon: 'mdi-phone-outline', variant: 'flat'}
      ]
    };
  },
  methods: {
    toggleDrawer() {
      this.$emit('toggle-drawer');
    },
  },
};
</script>

<style scoped>
/* Ваши стили здесь */
</style>

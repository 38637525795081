<template>
  <section class="text-center py-lg-0 py-15 pb-lg-15">
    <v-container>
      <span
          class="text-center text-uppercase text-lg-h6 font-weight-bold text-primary">Наши профессиональные услуги</span>
      <h2 class="mt-5 font-weight-medium text-uppercase text-md-h6 text-lg-h4 font-weight-bold">Ремонт и обслуживание
        автокондиционеров</h2>
      <p class="text-grey-darken-1">Обеспечим Вашему авто оптимальный климат</p>

      <v-row class="mt-16 align-content-center">
        <v-col cols="12" md="4" class="px-10">
          <div class="card-wrapper">
            <v-card class="overflow-visible mx-auto pb-5">
              <v-card-title>
                <div class="icon-wrapper">
                  <v-icon icon="mdi-car-seat-cooler" color="#41a7d2"/>
                </div>
              </v-card-title>
              <v-card-title class="text-h6 mt-10 font-weight-bold text-uppercase text-primary">
                Диагностика<br>автокондиционера
              </v-card-title>
              <v-card-text class="my-3 text-grey-darken-1">
                Мы осуществляем тщательную проверку вашего автокондиционера, выявляя любые неисправности и проблемы,
                чтобы обеспечить качественный ремонт и его эффективную работу
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                    variant="outlined"
                    rounded="xl"
                    size="x-large"
                    color="#FB8C00"
                    class="text-subtitle-2 text-lg-h6"
                    @click="$emit('open-form', 0)"
                >
                  Записаться
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="px-10 my-lg-0 my-15">
          <div class="card-wrapper">
            <v-card class="overflow-visible mx-auto pb-5">
              <v-card-title>
                <div class="icon-wrapper">
                  <v-icon icon="mdi-radiator" color="#41a7d2"/>
                </div>
              </v-card-title>
              <v-card-title class="text-h6 mt-10 font-weight-bold text-uppercase text-primary">
                Ремонт<br>системы охлаждения
              </v-card-title>
              <v-card-text class="my-3 text-grey-darken-1">
                Наши профессионалы занимаются качественным ремонтом систем охлаждения автомобилей, включая замену
                деталей и устранение утечек, чтобы ваше транспортное средство всегда оставалось прохладным и комфортным
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                    variant="flat"
                    rounded="xl"
                    size="x-large"
                    color="#FB8C00"
                    class="text-subtitle-2 text-lg-h6"
                    @click="$emit('open-form', 1)"
                >
                  <span class="text-white">Записаться</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="px-10">
          <div class="card-wrapper">
            <v-card class="overflow-visible mx-auto pb-5">
              <v-card-title>
                <div class="icon-wrapper">
                  <v-icon icon="mdi-car-seat-heater" color="#41a7d2"/>
                </div>
              </v-card-title>
              <v-card-title class="text-h6 mt-10 font-weight-bold text-uppercase text-primary">
                Заправка<br>и обслуживание
              </v-card-title>
              <v-card-text class="my-3 text-grey-darken-1">
                Мы предлагаем полный комплекс услуг по заправке, обслуживанию и чистке автокондиционеров, чтобы ваше
                устройство работало на максимальной производительности и было готово к любым погодным условиям
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                    variant="outlined"
                    rounded="xl"
                    size="x-large"
                    color="#FB8C00"
                    class="text-subtitle-2 text-lg-h6"
                    @click="$emit('open-form', 2)"
                >
                  Записаться
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script setup>
</script>

<style scoped>
.card-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-wrapper .v-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.overflow-visible {
  overflow: visible !important;
}

.icon-wrapper {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: lightgrey solid 2px;
}

.text-primary {
  color: #41a7d2 !important;
  font-size: 14px;
}

.text-grey-darken-1 {
  color: #757575;
}

.text-uppercase {
  text-transform: uppercase;
}
</style>

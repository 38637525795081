<template>
  <v-dialog
      :model-value="dialog"
      @update:model-value="updateDialog"
      max-width="500"
  >
    <v-form @submit.prevent="submitForm">
      <v-card prepend-icon="mdi-email" title="Отправить заявку">
        <v-card-text>
          <v-text-field
              v-model="formData.name"
              label="Имя"
              :error-messages="v$.formData.name.$errors.map(error => error.$message)"
              @blur="v$.formData.name.$touch"
              @input="v$.formData.name.$touch"
          ></v-text-field>
          <v-text-field
              v-model="formData.phone"
              label="Телефон *"
              :error-messages="v$.formData.phone.$errors.map(error => error.$message)"
              required
              v-mask="'+7(###)###-##-##'"
              @blur="v$.formData.phone.$touch"
              @input="v$.formData.phone.$touch"
          ></v-text-field>
<!--          <v-text-field-->
<!--              v-model="formData.email"-->
<!--              label="Email"-->
<!--              :error-messages="v$.formData.email.$errors.map(error => error.$message)"-->
<!--              @blur="v$.formData.email.$touch"-->
<!--              @input="v$.formData.email.$touch"-->
<!--          ></v-text-field>-->
          <v-select
              v-model="formData.selection"
              :items="selectionItems"
              label="Услуга *"
              :error-messages="v$.formData.selection.$errors.map(error => error.$message)"
              required
              :disabled="isSelectionDisabled"
              @blur="v$.formData.selection.$touch"
              @input="v$.formData.selection.$touch"
          ></v-select>
          <p class="text-subtitle-2"><span class="text-red">*</span> - обязательные поля</p>
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" color="red-darken-2" @click="closeDialog">Закрыть</v-btn>
          <v-btn type="submit" variant="flat" color="black" @keydown.enter="submitForm">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required, email} from '@vuelidate/validators';
import {mask} from 'vue-the-mask';

export default {
  directives: {
    mask,
  },
  props: {
    dialog: Boolean,
    choiceService: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:dialog', 'submit-form'],
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: '',
        selection: null, // Оставляем пустым, инициализируем в mounted
      },
      selectionItems: [
        'Диагностика автокондиционера',
        'Ремонт системы охлаждения',
        'Заправка и обслуживание',
      ],
    };
  },
  validations() {
    return {
      formData: {
        name: {},
        phone: {
          required,
          isValidPhone(value) {
            const cleaned = ('' + value).replace(/\D/g, '');
            return cleaned.length === 11; // Ensure exactly 11 digits
          },
        },
        email: {email},
        selection: {required},
      },
    };
  },
  computed: {
    isSelectionDisabled() {
      return this.choiceService !== null;
    },
  },
  methods: {
    async submitForm() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      try {
        const response = await fetch('https://suvic.goweb.kz/send_email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.formData),
        });

        const result = await response.text();

        if (response.ok) {
          this.$emit('submit-form', {...this.formData});
          this.closeDialog();
          console.log(result);
        } else {
          console.error(result);
        }
      } catch (error) {
        console.error('Ошибка при отправке формы:', error);
      }
    },
    resetForm() {
      this.formData.name = '';
      this.formData.phone = '';
      this.formData.email = '';
      this.formData.selection = this.choiceService !== null ? this.selectionItems[this.choiceService] : null; // Сброс к значению по индексу или null
      this.v$.$reset();
    },
    closeDialog() {
      this.resetForm();
      this.$emit('update:dialog', false);
    },
    updateDialog(value) {
      this.$emit('update:dialog', value);
    },
  },
  setup() {
    const v$ = useVuelidate();
    return {v$};
  },
  mounted() {
    if (this.choiceService !== null) {
      this.formData.selection = this.selectionItems[this.choiceService];
    }
  },
  watch: {
    choiceService(newVal) {
      this.formData.selection = newVal !== null ? this.selectionItems[newVal] : null;
    },
  },
};
</script>

<template>
  <section class="text-center about-bg">
    <v-container>
      <v-sheet
          class="d-flex flex-wrap mx-3 shed"
          elevation="20"
          rounded
      >
        <v-row>
          <v-col
              v-for="(col, index) in columns"
              :key="index"
              class="d-flex flex-wrap flex-grow-1 flex-basis-auto justify-center align-content-start gr-5 ga-lg-10 py-md-5 py-lg-10"
              cols="4"
              :style="col.style"
          >
            <img :src="require(`../assets/${col.img}`)" alt="icon" :height="isMobile ? '40' : '60'" />
            <div class="d-block">
              <p class="text-lg-h6" :class="col.textClass" style="font-size: 12px">{{ col.title }}</p>
              <br class="hidden-md-and-up">
              <p class="text-lg-h6 font-weight-medium" :class="col.subtextClass" style="font-size: 10px">{{ col.subtitle }}</p>
            </div>
          </v-col>
        </v-row>
      </v-sheet>

      <v-row class="mt-md-0 my-lg-16">
        <v-col cols="12" lg="5" class="pr-lg-10">
          <span class="text-center text-uppercase text-lg-h6 font-weight-bold" style="color: #41a7d2; font-size: 14px">О НАС</span>
          <h2 class="mt-5 font-weight-medium text-uppercase text-md-h6 text-lg-h4 font-weight-bold">МАСТЕРА КОМФОРТА</h2>
          <p class="text-grey-darken-1">Надежные решения для вашего комфорта в пути</p>
          <p class="mt-8 text-left">
            Добро пожаловать в нашу автомастерскую, где мы с гордостью предлагаем высококачественные услуги по ремонту и обслуживанию автокондиционеров, систем отопления и охлаждения автомобиля. Наша компания обладает многолетним опытом в этой области, и наш коллектив профессионалов стремится предоставить вам надежное и эффективное обслуживание.<br><br>
            Мы гордимся тем, что создали дружелюбное и доверительное окружение для наших клиентов. Здесь вы не просто становитесь нашими клиентами — вы становитесь частью нашей автомобильной семьи.<br><br>
            Доверьте нам заботу о вашем автомобиле, и мы гарантируем, что ваше водительское переживание будет всегда на высшем уровне.
          </p>
        </v-col>

        <v-col cols="12" lg="7">
          <div>
            <div class="about-counter hidden-md-and-down"></div>
            <div class="pa-5 hidden-md-and-down" style="background-color: #ECF6FB; border-radius: 0 0 20px 20px;">
              <v-row>
                <v-col v-for="(counter, index) in counters" :key="index" cols="12" lg="4">
                  <AnimatedCounter :end-value="counter.value" :duration="3" class="text-center" />
                  <p class="text-subtitle-2 text-uppercase font-weight-bold">{{ counter.label }}</p>
                  <p class="text-grey-darken-1">{{ counter.description }}</p>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <div class="pa-5 text-center hidden-md-and-up" style="background-color: #ECF6FB;">
    <v-row>
      <v-col v-for="(counter, index) in counters" :key="index" cols="12" lg="4">
        <AnimatedCounter :end-value="counter.value" :duration="3" class="text-center" />
        <p class="text-subtitle-2 text-uppercase font-weight-bold">{{ counter.label }}</p>
        <p class="text-grey-darken-1">{{ counter.description }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.shed {
  transform: translateY(-50%);
}

.about-bg {
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('../assets/bg-map.png');
  background-size: cover;
  background-position: center;
}

.about-counter {
  background: url('../assets/about_counter.png');
  background-size: cover;
  background-position: center;
  height: 340px;
  border-radius: 20px 20px 0 0;
}
</style>

<script setup>
import { ref } from 'vue';
import AnimatedCounter from '@/components/AnimatedCounter.vue';

const isMobile = ref(window.innerWidth <= 960);

const columns = [
  {
    img: 'clock.svg',
    title: 'Мы открыты Пн-Пт',
    subtitle: '10:00 - 20:00',
    style: 'background: #F2F2F2; border-radius: 20px 0 0 20px;',
    textClass: 'text-grey-darken-2',
    subtextClass: 'text-black'
  },
  {
    img: 'tools.svg',
    title: 'Есть вопросы? Позвоните нам',
    subtitle: '+7(707)920-83-24',
    style: 'background: #41a7d2',
    textClass: 'text-white',
    subtextClass: 'text-black'
  },
  {
    img: 'location.png',
    title: 'Нужен ремонт? Наш адрес:',
    subtitle: 'Ю-В, 137-й уч. квартал, ст. 056 ',
    style: 'background: #FB8C00; border-radius: 0 20px 20px 0;',
    textClass: '',
    subtextClass: 'text-white'
  }
];

const counters = [
  {
    value: 507,
    label: 'Бесплатных диагностик',
    description: 'Доверься опыту'
  },
  {
    value: 936,
    label: 'Довольных клиентов',
    description: 'Ваш комфорт — наш приоритет!'
  },
  {
    value: 723,
    label: 'Успешных ремонтов',
    description: 'Присоединяйся!'
  }
];
</script>

<template>
  <div>
    <!-- Компонент формы в диалоге -->
    <MailForm
        :dialog="dialog"
        :choice-service="choiceService"
        @update:dialog="$emit('update:dialog', $event)"
        @submit-form="handleFormSubmit"
        ref="mailForm"
    />

    <!-- Snackbar для отображения сообщения о отправке формы -->
    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        color="success"
        rounded="pill"
        variant="tonal"
    >
      Ваша заявка успешно отправлена
    </v-snackbar>
  </div>
</template>

<script>
import {ref} from 'vue';
import MailForm from './MailForm.vue';

export default {
  components: {
    MailForm,
  },
  props: {
    dialog: Boolean,
    choiceService: {
      type: Number,
      default: null,
    },
  },
  setup(props, {emit}) {
    const snackbar = ref(false);
    const mailForm = ref(null);

    const handleFormSubmit = (formData) => {
      console.log('Form submitted:', formData);

      // Отображение сообщения Snackbar
      snackbar.value = true;

      // Закрытие диалога и сброс формы
      emit('update:dialog', false);
      mailForm.value.resetForm();
    };

    return {
      snackbar,
      mailForm,
      handleFormSubmit,
    };
  },
};
</script>

<template>
  <div ref="counter">
    <span class="counter text-h3 font-weight-bold" style="color: #41a7d2">{{ formattedValue }}</span>
  </div>
</template>

<script>
import {CountUp} from 'countup.js';

export default {
  props: {
    endValue: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 2 // Продолжительность анимации в секундах
    },
    startValue: {
      type: Number,
      default: 0 // Начальное значение счётчика
    }
  },
  data() {
    return {
      observer: null,
      animated: false,
      countUp: null
    };
  },
  computed: {
    formattedValue() {
      // Форматируем значение, если необходимо
      return this.startValue;
    }
  },
  mounted() {
    this.setupObserver();
  },
  beforeUnmount() {
    // Отключаем наблюдение за элементом при удалении компонента
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    setupObserver() {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.animated) {
            this.animateCounter();
            this.animated = true;
            // Отключаем наблюдение после первого запуска анимации, если нужно
            this.observer.disconnect();
          }
        });
      });

      this.observer.observe(this.$refs.counter);
    },
    animateCounter() {
      const options = {
        duration: this.duration
      };

      this.countUp = new CountUp(this.$refs.counter.querySelector('.counter'), this.endValue, options);
      if (!this.countUp.error) {
        this.countUp.start();
      } else {
        console.error(this.countUp.error);
      }
    }
  }
};
</script>

<template>
  <section class="d-flex flex-column justify-center align-center text-center text-lg-left hero-section"
           style="height: calc(100vh - 60px)">
    <v-container>

      <h1 class="text-lg-h3 text-md-h5 font-weight-medium text-uppercase text-white" style="line-height: 1.5">
        <span class="text-lg-h4 font-weight-medium text-orange">МАСТЕРСКИЙ</span><br>РЕМОНТ И ДИАГНОСТИКА<br>АВТОКОНДИЦИОНЕРОВ
      </h1>

      <v-btn
          rounded="xl"
          size="x-large"
          color="#41a7d2"
          class="mt-16 text-subtitle-2 text-lg-h6"
          @click="openForm(null)"
      >
        Оставить заявку
      </v-btn>
      <mail-form-snackbar
          :dialog="dialog"
          :choice-service="choiceService"
          @update:dialog="dialog = $event"
          @submit-form="handleFormSubmit"
          ref="mailForm"
      />

    </v-container>


  </section>
</template>

<script>
import {ref} from 'vue';
import MailFormSnackbar from '@/components/MailFormSnackbar.vue';

export default {
  components: {
    MailFormSnackbar,
  },
  setup() {
    const dialog = ref(false);
    const snackbar = ref(false);
    const choiceService = ref(null);
    const mailForm = ref(null);

    const handleFormSubmit = (formData) => {
      console.log('Form submitted:', formData);

      // Отображение сообщения Snackbar
      snackbar.value = true;

      // Закрытие диалога и сброс формы
      dialog.value = false;
      mailForm.value.resetForm();
    };

    const openForm = (service) => {
      choiceService.value = service;
      dialog.value = true;
    };

    return {
      dialog,
      snackbar,
      choiceService,
      mailForm,
      handleFormSubmit,
      openForm,
    };
  },
};
</script>

<style scoped>
.hero-section {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('../assets/hero.jpg');
  background-size: cover;
  background-position: 0 center;
}
</style>

<template>
  <v-app>
    <AppHeader @toggle-drawer="toggleDrawer"/>

    <!-- Основное содержимое страницы -->
    <v-main>
      <HeroSection @open-form="openForm"/>
      <AboutSection/>
      <ServicesSection @open-form="openForm"/>
    </v-main>

    <!-- Форма и Snackbar -->
    <mail-form-snackbar
        :dialog="dialog"
        :choice-service="choiceService"
        @update:dialog="dialog = $event"
        @submit-form="handleFormSubmit"
    />
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import HeroSection from "@/components/HeroSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import ServicesSection from "@/components/ServicesSection.vue";
import MailFormSnackbar from "@/components/MailFormSnackbar.vue";

export default {
  name: 'App',

  components: {
    AppHeader,
    HeroSection,
    AboutSection,
    ServicesSection,
    MailFormSnackbar,
  },

  data: () => ({
    dialog: false,
    choiceService: null,
  }),

  methods: {
    toggleDrawer() {
      // Логика для открытия/закрытия боковой панели
    },

    openForm(service) {
      this.choiceService = service;
      this.dialog = true;
    },

    handleFormSubmit(formData) {
      console.log('Form submitted:', formData);
      // Дополнительные действия по необходимости
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6, p, span, a {
  font-family: "Jost", sans-serif !important;
}

.v-container {
  max-width: 1400px;
}
</style>
